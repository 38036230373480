import React, { useState, useEffect } from 'react'
import {
    DetailsList,
} from 'office-ui-fabric-react'
import { Api } from '../../services/api'
import { FaRegImage, FaSearch } from 'react-icons/fa';
import DialogPlaca from './DialogPlaca'
import Moment from 'moment';

export default () => {
    const [vehicles, setVehicles] = useState([]);
    const [selection, setSelection] = useState(null);
    const [placa, setPlaca] = useState("");
    const [start, setStart] = useState(Moment().format("YYYY-MM-DD"));
    const [end, setEnd] = useState(Moment().format("YYYY-MM-DD"));

    const buscar = () => {
        Api.busca(start, end, placa).then((dados)=>{
            setVehicles(dados) 
        })
    }

    const closeDialog = ()=>{setSelection(null)}

    const reload = () => {
        setPlaca("")
    }

    useEffect(() => {
        reload()
    }, [])
    
    const placaChange = (data)=>{
        const dataTarget = data.target.value;
        if(dataTarget){
            const dataTargetUpper = dataTarget.toUpperCase();
            data.target.value = dataTargetUpper;
            setPlaca(dataTargetUpper);
        }else{
            setPlaca("");
        }
    }


    const logout = () =>{
        Api.logout();
    }

    const _renderItemColumn = (item, index, column) => {
        const { key } = column
        let fieldContent = item[key]
        switch (key) {
            case 'edit':
                return (
                    <div style={{ display: 'flex' }}>
                        <FaRegImage style={{fontSize: "20px", cursor: "pointer", color: "#808080", marginLeft: "1rem"}} onClick={()=>{setSelection(item)}}/>
                    </div>
                )
            default:
                return <span>{fieldContent}</span>
        }
    }
    return (
        <div style={{display: "grid", gridTemplateColumns: "1fr", height: "95%"}}>
            <div style={{display: "flex", alignItems: "center", backgroundColor: "#007bff", padding: 5}}>
                <img src={`logo_nome.png`} style={{height: "55px", alignSelf: "end"}} />
                <label style={{marginLeft: 10,marginRight: 10}}>Data Inicial</label>
                <input type="date" className="form-control" style={{maxWidth: 200}} defaultValue={start} onChange={(data)=>{setStart(data.target.value)}} />
                <label style={{marginLeft: 10,marginRight: 10}}>Data Final</label>
                <input type="date" className="form-control" style={{maxWidth: 200}} defaultValue={end} onChange={(data)=>{setEnd(data.target.value)}} />
                <label style={{marginLeft: 10,marginRight: 10}}>Placa</label>
                <input type="text" className="form-control" style={{maxWidth: 200}} defaultValue={placa} onChange={placaChange}/>

                <FaSearch style={{fontSize: "25px", cursor: "pointer", color: "#fff", marginLeft: "1rem", alignSelf: 'center'}} onClick={buscar}/>

                <span style={{cursor: "pointer", color: "#fff", marginLeft: "auto", marginRight: "20px"}} onClick={logout}>SAIR</span>
            </div>
            <div style={{overflow: "auto", height: "calc(100vh - 65px)"}}>
                <DetailsList
                    items={vehicles}
                    setKey="none"
                    selectionMode={0}
                    columns={columns}
                    onRenderItemColumn={_renderItemColumn}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                />
            </div>
            {selection && (
                <DialogPlaca data={selection} close={closeDialog}/>
            )}
        </div>
    )
}

const columns = [
    { key: 'descricao_empresa', name: 'Empresa', maxWidth: 100, minWidth: 100 },
    { key: 'data_hora', name: 'Data Hora', maxWidth: 200, minWidth: 200 },
    { key: 'placa', name: 'Placa', maxWidth: 75, minWidth: 75 },
    { key: 'camera_nome', name: 'Nome Camera', maxWidth: 200, minWidth: 200 },
    { key: 'edit', name: 'Ação', maxWidth: 200, minWidth: 200 },
]
