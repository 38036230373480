const axios = require('axios')

let USER = JSON.parse(localStorage.getItem("USER")) || {};
let onLogin = () => {};

export const Api = {
    execute(route = "", method = "GET", data = null) {
        return new Promise((resolve, reject) => {
            let options = (data && {
                method,
                data: JSON.stringify(data)
            }) || { method };

            axios("https://eztraffic.ezwaysolucoes.com.br:20004/" + route, {
            // axios("https://web.warusky.com.br:20002/" + route, {
                ...options,
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${USER && USER.token ? USER.token : ``}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then((response)=>resolve(response.data))
                .catch(reject);
        });
    },
    busca(data_ini, data_fin, placa) {
        return this.execute(`movimentos?data_ini=${data_ini}&data_fin=${data_fin}&placa=${placa}`)
    },
    async login(credentials) {
        const result = await this.execute("login", "POST", credentials);
        console.log(result)
        if (result.token) {
            USER = result;
            localStorage.setItem("USER", JSON.stringify(USER));
            onLogin();
            return USER;
        }
        throw result;
    },
    logout(credentials) {
        localStorage.removeItem('USER');
     
        window.location.href = "/";
        
    },
};

export const setOnLogin = callback => {
    onLogin = callback;
};
export const getUser = () => USER;

