import React from "react";
import { Stack, StackItem } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";
import { MdClose } from 'react-icons/md';
const color = "#0078d4";

export default ({ hidden, close, children, title }) => {
    if (hidden) return <></>;
    return (
        <div
            style={{
                display: "flex",
                position: "absolute",
                width: "100vw",
                height: "70vh",
                backgroundColor: "rgba(255,255,255,0.2)",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1
            }}
        >
            <Stack
                style={{
                    backgroundColor: "white",
                    boxShadow: Depths.depth64,
                    padding: 24,
                    paddingTop: 16,
                    borderTop: "4px solid " + color,
                    borderRadius: "2px 2px 0 0",
                    marginTop: 32,
                    maxWidth: "100vw",
                    maxHeight: "90vh"
                }}
            >
                <StackItem
                    styles={{
                        root: {
                            display: "flex",
                            marginBottom: 16,
                            justifyContent: "space-between"
                        }
                    }}
                >
                    <Text style={{ color }} variant={"xLarge"}>
                        {title}
                    </Text>
                    <MdClose
                        onClick={close}
                    />
                </StackItem>
                <div style={{ overflow: "auto" }}>{children}</div>
            </Stack>
        </div>
    );
};
