import React from "react";
import {
    Stack,
    StackItem,
    Label,
    Text,
} from "office-ui-fabric-react";
import Dialog from "../../../components/Dialog";
import  Zoom  from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default ({ alert, data, close }) => {
    if (!data) return null;

    let { placa, data_hora, imagem, descricao_empresa } = data;


    return (
        <Dialog
            hidden={!data}
            close={close}
            title={"Veiculo Capturado"}
        >
            <Stack
                horizontal
                tokens={{
                    childrenGap: 32
                }}
            >
                <Stack
                    tokens={{
                        childrenGap: 8
                    }}
                >
                    
                <StackItem>
                <Zoom zoomMargin={40}>
                    <img
                        src={`https://eztraffic.ezwaysolucoes.com.br:20004/${imagem}`}
                        alt="Golden Gate Bridge"
                        className="img"
                        style={{ 
                            resizeMode: "contain", /** `Para manter a proporção da imagem */
                            width: 400
                        }}
                    />
                </Zoom>
                    
                </StackItem>
                <StackItem>
                        <Label>Placa</Label>
                        <Text variant={"xLarge"}>{placa}</Text>
                        <Label>Data</Label>
                        <Text>{data_hora}</Text>
                        <Label>Empresa</Label>
                        <Text>{descricao_empresa || "-"}</Text>
                    </StackItem>
                </Stack>
            </Stack>
        </Dialog>
    );
};
