import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Context from "./context";
import Authentication from "../src/pages/authentication"
import Home from "../src/pages/Home"
import { Api, setOnLogin, getUser } from "./services/api";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUser(),
        };
        setOnLogin(this.onLogin);
    }

    componentDidMount() {
        if (getUser().token) this.onLogin();
    }
    onLogin = () => {
        this.setState({ user: getUser() });
    };

    render() {
        const { user: { token } } = this.state;
        if (!token) return <Authentication />;

        return (
            <>
                <Context.Provider
                    value={{
                        Api,
                        ...this.state
                    }}
                >
                    <BrowserRouter ref={ref => (this.router = ref)}>
                        <Switch>
                            <Route  path="/home" component={Home} />
                            <Redirect from="*" to="/home" />
                        </Switch>
                    </BrowserRouter>
                </Context.Provider>
            </>
        );
    }
}
