import React, { useEffect, useState  } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { Dialog, DialogType, TextField, PrimaryButton } from 'office-ui-fabric-react';
import './style.css';
import { Api } from '../../services/api'

export default ()=>{
    const [login, setLogin] = useState("");
    const [senha, setSenha] = useState("");
    const [setPage, setResponse] = useState({});
    const { id, messageError } = setPage
    useEffect(() => {
        if (messageError)
            setTimeout(() => {
                setResponse({})
            }, 3500)
    }, [messageError])


    async function handleLogin(){
        let form = {"usuario": login, "senha": senha}
        try {
            await Api.login(form)
        } catch (err) {
            console.log('erro', err)
            setResponse(err)
        }
    }
    function handleEnter(e){
        if(e.key == "Enter"){
            handleLogin()
        }
    }
    return (
        <div className="fundoLogin">
            <div className="contentLogin ">
            <Dialog
                hidden={false}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: <img src={`logo_nome_transparente.png`} style={{height: "55px", alignSelf: "end", marginLeft: "55px" }} />,
                }}
                styles={{ width: 400 } }
            >
                {messageError? (
                    <UncontrolledAlert color="primary">{messageError}</UncontrolledAlert>
                ) : ""}
                <TextField label="Usuario" onChange={(_, v) => setLogin(v)} onKeyPress={handleEnter}/>
                <TextField label="Senha" type="password" onChange={(_, v) => setSenha(v)} onKeyPress={handleEnter}/>

                    <PrimaryButton className=" mb-4 mt-4 btn-entrar" onClick={handleLogin}>Entrar</PrimaryButton>
                </Dialog>
            </div>
        </div>
    )
}
